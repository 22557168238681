import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useJsonToCsv } from 'react-json-csv';
import {
  Button,
  Table,
  Tabs,
  Image,
  Input,
  Avatar,
  notification,
  Tooltip,
  Select,
  Popconfirm,
  DatePicker,
  Card
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { useRecoilValue, useSetRecoilState, useRecoilState, useRecoilValueLoadable } from 'recoil';
import {
  timezoneAtom,
  profileAtom
} from '../../../atoms/Atoms';
import { businessesSelector } from '../../../atoms/Selectors';
import api from '../../../api/api';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';
import { thousand, toCurrency } from '../../utils/functions';
import envConfig from '../../../envConfig';
import { dateRangePresets } from '../../utils/utils';

function InventoryHistoryReport() {
  const bottomRowGridStyle = { width: '100%', textAlign: 'center' };
  const { saveAsCsv } = useJsonToCsv();
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [reportDateRange, setReportDateRange] = useState({
    start: moment().startOf('day').format('YYYY-MM-DD'),
    end: moment().endOf('day').format('YYYY-MM-DD'),
  });
  const timezone = useRecoilValue(timezoneAtom);
  const businesses = useRecoilValueLoadable(businessesSelector);
  const [loadingDataForExport, setLoadingDataForExport] = useState(false);
  const profile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
  profile.contents && (profile?.contents?.roles?.includes('SuperAdmin'));
  const isStoreAdmin =
  isAdmin || (profile?.contents && profile?.contents?.roles?.includes('StoreAdmin'));
  const isStoreManager = isStoreAdmin || profile?.contents?.roles?.includes('StoreManager');
  const [report, setReport] = useState({});
  const [reportTypeFilter, setReportTypeFilter] = useState('');
  const [lastSearchReport, setLastSearchReport] = useState('');
  const [selectedBusinessId, setSelectedBusinessId] = useState('');
  const [isSearchingReport, setIsSearchingReport] = useState(false);
  const [currentPageReport, setCurrentPageReport] = useState(0);
  const [sizeReport, setSizeReport] = useState(20);

  const reportColumns = [
    {
      title: 'GTIN',
      dataIndex: 'gtin',
      key: 'gtin',
      align: 'left',
      className: 'text-xs',
      render: (text) =>
        <span>{text}</span>,
    },
    {
      title: t('item'),
      dataIndex: 'itemName',
      key: 'itemName',
      align: 'left',
      className: 'text-xs',
      render: (text) =>
        <span>{text}</span>,
    },
    {
      title: t('amount_moved'),
      dataIndex: 'movementAmount',
      key: 'movementAmount',
      align: 'left',
      className: 'text-xs',
      render: (text) =>
        <span>{thousand(text)}</span>,
    },
    {
      title: t('value_moved'),
      dataIndex: 'movementValue',
      key: 'movementValue',
      align: 'left',
      className: 'text-xs',
      render: (text) =>
        <span>{toCurrency(text) === '-' ? '$0.00' : toCurrency(text)}</span>,
    },
    {
      title: t('current_quantity_on_hand'),
      dataIndex: 'totalQuantityOnHand',
      key: 'totalQuantityOnHand',
      align: 'left',
      className: 'text-xs',
      render: (text) =>
        <span>{thousand(text)}</span>,
    },
    {
      title: t('current_quantity_on_hand_value'),
      dataIndex: 'totalQuantityOnHandValue',
      key: 'totalQuantityOnHandValue',
      align: 'left',
      className: 'text-xs',
      render: (text) =>
        <span>{toCurrency(text) === '-' ? '$0.00' : toCurrency(text)}</span>,
    }
  ];

  const exportFields = {
    gtin: 'GTIN',
    itemName: t('item'),
    movementAmount: t('amount_moved'),
    movementValue: t('value_moved'),
    totalQuantityOnHand: t('current_quantity_on_hand'),
    totalQuantityOnHandValue: t('current_quantity_on_hand_value'),
  };

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function mapReport(tempReport) {
    return _.map(tempReport, (m) => ({
      ...m,
      itemName: i18n.language === 'en' ?
        m.name.trim() :
        m.nameSpa.trim()
    }));
  }

  function mapExportReport(tempReport) {
    return _.map(tempReport, (m) => ({
      ...m,
      itemName: (i18n.language === 'en' ?
        m.name.trim() :
        m.nameSpa.trim())
        .replaceAll(',', ' ')
    }));
  }

  function searchReport(query, page) {
    setIsSearchingReport(true);
    let tempType = null;
    if (reportTypeFilter !== '') {
      tempType = parseInt(reportTypeFilter, 10);
    }
    const payload = {
      Query: query,
      Page: page,
      Size: sizeReport,
      Type: tempType,
      BusinessId: selectedBusinessId,
      StartDate: dayjs(reportDateRange.start).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DDTHH:mm:ss.000'),
      EndDate: dayjs(reportDateRange.end).endOf('day').add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DDTHH:mm:ss.000'),
    };
    api
      .post(
        'inventory/history/report',
        payload
      )
      .then((response) => {
        setIsSearchingReport(false);
        if (response.data.success) {
          setReport({
            ...response.data.data,
            report: mapReport(response.data.data.report)
          });
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setIsSearchingReport(false);
        showMessage(error.message);
      });
  }

  function getExportData() {
    setLoadingDataForExport(true);
    let tempType = null;
    if (reportTypeFilter !== '') {
      tempType = parseInt(reportTypeFilter, 10);
    }
    const payload = {
      Query: lastSearchReport,
      Type: tempType,
      BusinessId: selectedBusinessId,
      StartDate: dayjs(reportDateRange.start).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DDTHH:mm:ss.000'),
      EndDate: dayjs(reportDateRange.end).endOf('day').add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DDTHH:mm:ss.000'),
    };
    api
      .post(
        'inventory/history/report/all',
        payload
      )
      .then((response) => {
        setLoadingDataForExport(false);
        if (response.data.success) {
          const data = mapExportReport(response.data.data);
          saveAsCsv({
            data,
            fields: exportFields,
            filename: `inventory_history_report_${reportDateRange.start}_${reportDateRange.end}`,
          });
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoadingDataForExport(false);
        showMessage(error.message);
      });
  }

  function reportSearch(value) {
    setLastSearchReport(value);
    setCurrentPageReport(0);
    searchReport(value, 0);
  }

  function onReportTableChange(page, pageSize) {
    if (page - 1 !== currentPageReport) {
      setCurrentPageReport(page - 1);
      searchReport(lastSearchReport, page - 1);
    } else {
      setSizeReport(pageSize);
    }
  }

  useEffect(() => {
    reportSearch('');
  }, []);

  // useEffect(() => {
  //   reportSearch(lastSearchReport);
  // }, [reportDateRange]);

  return (
    <div>
      <dl className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-3 lg:grid-cols-5">
        <Card
          type="inner"
          title={t('current_quantity_on_hand')}
          className="rounded-xl bg-gradient-to-br from-blue-200 to-white"
          hoverable
          loading={isSearchingReport}
        >
          <Card.Grid
            className="bg-white"
            hoverable={false}
            style={bottomRowGridStyle}
          >
            <span>{thousand(report?.totalQuantityOnHand)} : {toCurrency(report?.totalQuantityOnHandValue) === '-' ? '$0.00' : toCurrency(report?.totalQuantityOnHandValue)}</span>
          </Card.Grid>
        </Card>
        <Card
          type="inner"
          title={t('sale')}
          className="rounded-xl bg-gradient-to-br from-blue-200 to-white"
          hoverable
          loading={isSearchingReport}
        >
          <Card.Grid
            className="bg-white"
            hoverable={false}
            style={bottomRowGridStyle}
          >
            <span>{thousand(report?.totalSale)} : {toCurrency(report?.totalSaleValue) === '-' ? '$0.00' : toCurrency(report?.totalSaleValue)}</span>
          </Card.Grid>
        </Card>
        <Card
          type="inner"
          title={t('receiving')}
          className="rounded-xl bg-gradient-to-br from-blue-200 to-white"
          hoverable
          loading={isSearchingReport}
        >
          <Card.Grid
            className="bg-white"
            hoverable={false}
            style={bottomRowGridStyle}
          >
            <span>{thousand(report?.totalReceiving)} : {toCurrency(report?.totalReceivingValue) === '-' ? '$0.00' : toCurrency(report?.totalReceivingValue)}</span>
          </Card.Grid>
        </Card>
        <Card
          type="inner"
          title={t('adjustment_up')}
          className="rounded-xl bg-gradient-to-br from-blue-200 to-white"
          hoverable
          loading={isSearchingReport}
        >
          <Card.Grid
            className="bg-white"
            hoverable={false}
            style={bottomRowGridStyle}
          >
            <span>{thousand(report?.totalAdjustmentUp)} : {toCurrency(report?.totalAdjustmentUpValue) === '-' ? '$0.00' : toCurrency(report?.totalAdjustmentUpValue)}</span>
          </Card.Grid>
        </Card>
        <Card
          type="inner"
          title={t('adjustment_down')}
          className="rounded-xl bg-gradient-to-br from-blue-200 to-white"
          hoverable
          loading={isSearchingReport}
        >
          <Card.Grid
            className="bg-white"
            hoverable={false}
            style={bottomRowGridStyle}
          >
            <span>{thousand(report?.totalAdjustmentDown)} : {toCurrency(report?.totalAdjustmentDownValue) === '-' ? '$0.00' : toCurrency(report?.totalAdjustmentDownValue)}</span>
          </Card.Grid>
        </Card>
      </dl>
      <div className="flex my-2">
        <Button
          type="primary"
          size="small"
          className="ml-auto"
          disabled={isSearchingReport}
          loading={loadingDataForExport}
          icon={<CloudDownloadOutlined />}
          onClick={() => {
            getExportData();
          }}
        >
          {t('export')}
        </Button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-2 lg:flex">
        <Input.Search
          key="reportSearch"
          disabled={isSearchingReport || loadingDataForExport}
          placeholder={t('item_search_placeholder')}
          allowClear
          enterButton={t('search')}
          size="large"
          onSearch={reportSearch}
          className="md:col-span-3 lg:col-auto"
        />
        <RangePicker
          format="YYYY-MM-DD"
          disabled={isSearchingReport || loadingDataForExport}
          value={[dayjs(reportDateRange?.start), dayjs(reportDateRange?.end)]}
          presets={dateRangePresets(t)}
          onChange={(date, dateString) => {
            setReportDateRange({
              start: dateString[0],
              end: dateString[1],
            });
          }}
        />
        <Select
          className="h-10 lg:w-56"
          disabled={isSearchingReport || loadingDataForExport}
          onChange={(value) => setReportTypeFilter(value)}
          value={reportTypeFilter}
        >
          <Option value="">{t('all')}</Option>
          <Option value="0">{t('sale')}</Option>
          <Option value="1">{t('adjustment_up')}</Option>
          <Option value="2">{t('adjustment_down')}</Option>
          <Option value="3">{t('receiving')}</Option>
        </Select>
        <Select
          className="h-10 lg:w-56"
          disabled={isSearchingReport || loadingDataForExport}
          onChange={(value) => setSelectedBusinessId(value)}
          value={selectedBusinessId}
        >
          <Option
            key="general"
            value=""
          >
            {t('any')}
          </Option>
          {_.map(businesses?.contents?.data?.data?.results, (b) => (
            <Option
              key={b.id}
              value={b.id}
            >
              {b.name}
            </Option>
          ))}
        </Select>
      </div>
      <Table
        size="small"
        className="mt-2"
        loading={isSearchingReport}
        bordered
        pagination={{
          pageSize: sizeReport,
          showSizeChanger: true,
          defaultCurrent: 0,
          current: currentPageReport + 1,
          total: report?.total,
          onChange: onReportTableChange
        }}
        dataSource={report?.report}
        columns={reportColumns}
      />
    </div>
  );
}

export default InventoryHistoryReport;
