import React, { useState, useEffect } from 'react';
import { useMount } from 'react-use';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { useTranslation } from 'react-i18next';
import 'moment-timezone';
import _ from 'lodash';
import { useJsonToCsv } from 'react-json-csv';
import {
  Button,
  Table,
  Card,
  Modal,
  DatePicker,
} from 'antd';
import dayjs from 'dayjs';
import { PageHeader } from '@ant-design/pro-layout';
import {
  ReloadOutlined,
} from '@ant-design/icons';
import { timezoneAtom, profileAtom, giftCardHistoryAtom } from '../../../../atoms/Atoms';
import { thousandv2, toCurrency, renderShortDateTime } from '../../../utils/functions';
import api from '../../../../api/api';
import { dateRangePresets } from '../../../utils/utils';

function GiftCardsHistoryModal() {
  const bottomRowGridStyle = { width: '100%', textAlign: 'center' };
  const { RangePicker } = DatePicker;
  const { t } = useTranslation();
  const { saveAsCsv } = useJsonToCsv();
  const timezone = useRecoilValue(timezoneAtom);
  const profile = useRecoilValueLoadable(profileAtom);
  const [selectedGiftCard, setSelectedGiftCard] = useRecoilState(giftCardHistoryAtom);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentSize, setCurrentSize] = useState(20);

  const isAdmin =
    profile.contents && profile?.contents?.roles?.includes('SuperAdmin');
  const isStoreManager =
    isAdmin ||
    (profile?.contents &&
      (profile?.contents?.roles?.includes('StoreAdmin') ||
        profile?.contents?.roles?.includes('Owner')));
  const canViewFinance =
    isAdmin ||
    isStoreManager ||
    (profile.contents &&
      (profile.contents.roles.includes('Finance') ||
        profile.contents.roles.includes('TopAnalytics')));
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(false);

  function getTransactionType(type) {
    if (type === 1) {
      return t('redemption');
    }
    return t('load');
  }

  const width = window.innerWidth;
  const allColumns = [{
    title: t('day'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'center',
    className: 'text-xs',
    render: (text) => <span>{renderShortDateTime(text, timezone)}</span>,
  },
  {
    title: t('transaction'),
    dataIndex: 'transactionType',
    key: 'transactionType',
    align: 'center',
    className: 'text-xs',
    render: (text) => <span>{getTransactionType(text)}</span>,
  },
  {
    title: t('amount'),
    dataIndex: 'amount',
    key: 'amount',
    align: 'right',
    className: 'text-xs',
    render: (text) => <span>{toCurrency(text)}</span>,
  },
  {
    title: t('current_balance'),
    dataIndex: 'giftCardCurrentBalance',
    key: 'giftCardCurrentBalance',
    align: 'center',
    className: 'text-xs',
    render: (text) => <span>{toCurrency(text)}</span>,
  },
  {
    title: t('order'),
    dataIndex: 'orderConfirmationCode',
    key: 'orderConfirmationCode',
    align: 'right',
    className: 'text-xs',
    render: (text) => <span>{text}</span>,
  },
  {
    title: t('business'),
    dataIndex: 'orderBusinessName',
    key: 'orderBusinessName',
    align: 'right',
    className: 'text-xs',
    render: (text) => <span>{text}</span>,
  }];

  function getData(page) {
    setLoading(true);
    const data = {
      Page: page,
      Size: currentSize
    };
    api
      .post(
        `giftcards/history/${selectedGiftCard?.id}`,
        data
      )
      .then((response) => {
        setLoading(false);
        setReportData(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  useEffect(() => {
    if (selectedGiftCard) {
      setCurrentPage(0);
      getData(0);
    } else {
      setReportData(null);
    }
  }, [currentSize, selectedGiftCard]);

  return (
    <Modal
      width={600}
      title={`${t('gift_card')} - ${selectedGiftCard?.code}`}
      open={selectedGiftCard}
      onCancel={() => {
        setSelectedGiftCard(null);
      }}
      footer={[
        <Button
          key="close"
          type="primary"
          danger
          loading={loading}
          onClick={() => {
            setSelectedGiftCard(null);
          }}
        >
          {t('close')}
        </Button>
      ]}
    >
      <PageHeader
        className="mb-4 mt-4 px-0"
        title={t('history')}
        extra={
          [
            <div className="space-x-2">
              <br className={width < 1024 ? null : 'hidden'} />
              <Button
                type="primary"
                size="small"
                icon={<ReloadOutlined />}
                onClick={() => {
                  setCurrentPage(0);
                  getData(0);
                }}
              >
                {t('refresh')}
              </Button>
            </div>
          ]
        }
      />
      <Table
        loading={loading}
        bordered
        pagination={{
          pageSize: currentSize,
          showSizeChanger: true,
          defaultCurrent: 0,
          current: currentPage + 1,
          total: reportData?.total,
          onChange: (page, pageSize) => {
            if (page - 1 !== currentPage) {
              setCurrentPage(page - 1);
              getData(page - 1);
            } else {
              setCurrentSize(pageSize);
            }
          }
        }}
        columns={allColumns}
        dataSource={reportData?.transactions}
      />
    </Modal>
  );
}

export default GiftCardsHistoryModal;
